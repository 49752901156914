import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {TeamPosition} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IHorse} from "data/types/race";
import type {IRivalTeamStore} from "data/stores/rival_team/rival_team.store";
import {ITeam} from "data/types/team";

interface IParams {
	position: TeamPosition;
}

export interface IRivalHorseController extends ViewController<IParams> {
	get i18n(): ILocalizationStore;

	get isCaptain(): boolean;

	get isPerfect(): boolean;

	get hasTeam(): boolean;

	get isDonkeyInsurance(): boolean;

	get points(): number | undefined;

	get horse(): IHorse | undefined;
}

@injectable()
export class RivalHorseController implements IRivalHorseController {
	@observable private _position: TeamPosition = TeamPosition.FirstPlace;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RivalTeamStore) private _rivalTeamStore: IRivalTeamStore
	) {
		makeAutoObservable(this);
	}

	get hasTeam(): boolean {
		return Boolean(this._rivalTeamStore.team);
	}

	get points(): number | undefined {
		if (!this.team) {
			return undefined;
		}

		return Object.values(this.team.scoreDetails || {}).reduce((acc, value) => {
			if (value?.horse === this.horseId) {
				return acc + value.pts;
			}
			return acc;
		}, 0);
	}

	get isCaptain(): boolean {
		return this.horseId === this.team?.captain;
	}

	get isPerfect(): boolean {
		return this._rivalTeamStore.isPerfectTeam;
	}

	get isDonkeyInsurance(): boolean {
		return this.horseId === this.team?.donkeyInsurance;
	}

	get horse(): IHorse | undefined {
		return this._rivalTeamStore.getHorseByPosition(this._position);
	}

	private get team(): ITeam | undefined {
		return this._rivalTeamStore.team;
	}

	private get horseId() {
		return this._rivalTeamStore.getHorseIdByPosition(this._position);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._position = param.position;
	}

	onChange(param: IParams): void {
		this._position = param.position;
	}
}
