import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {Button, Container, useMediaQuery} from "@mui/material";
import {NavLink, useLocation} from "react-router-dom";
import {ReactComponent as Logo} from "assets/img/logo/logo_white.svg";
import {useViewController} from "data/services/locator";
import {IHeaderController} from "views/components/header/header.controller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";
import {MenuButton} from "views/components/menu_button/menu_button.component";
import {Menu} from "views/components/menu/menu.component";
import {Sharing} from "views/components/sharing/sharing.component";
import InfoIcon from "@mui/icons-material/Info";
import {ShareType} from "data/enums";
import {Notification} from "views/components/notification/notification.component";
import {ReactComponent as AccountIcon} from "assets/img/icons/account.svg";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";
import {TutorialTooltip} from "views/components/tutorial_tooltip/tutorial_tooltip.component";

const Wrapper = styled.header`
	width: 100%;
	height: 76px;
	padding: 12px;
	background: var(--primaryColorDark);
	position: relative;

	@media screen and (max-width: 820px) {
		//height: 56px;
		height: unset;
		padding: 0;
	}
`;

const Navigation = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;

	a {
		height: 52px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 16px;
		font-style: normal;
		line-height: 140%; /* 22.4px */
		padding: 0 6px;

		&.active {
			color: var(--iconColorMedium);
		}
	}
`;

const LogoLink = styled(NavLink)`
	height: 100%;

	svg {
		height: 100%;
		width: auto;
	}
`;

const MobileContainer = styled.div`
	padding: 8px 18px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	height: 56px;
`;

const SharingWrapper = styled.div`
	margin-left: auto;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 24px;

	button {
		width: 24px;
		height: 24px;
		min-width: unset;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ffffff;
	}

	@media screen and (max-width: 820px) {
		gap: 12px;
	}
`;

const AccountButton = styled(Button)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 6px;
	width: unset !important;
	max-width: unset !important;

	span {
		font-size: 16px;
		font-style: normal;
		font-weight: 450;
		line-height: 140%; /* 22.4px */
	}

	svg {
		width: 18px;
		height: auto;
	}
`;

export const Header: React.FC = observer(() => {
	const {i18n, isAuthorized, isMenuOpen, openAuth, toggleMenu, openTutorial, isHideLanding} =
		useViewController<IHeaderController>(Bindings.HeaderController);
	const isMobile = useMediaQuery("(max-width: 820px)");

	const location = useLocation();

	if (isMobile) {
		return (
			<React.Fragment>
				<Wrapper>
					<MobileContainer>
						<MenuButton isOpen={isMenuOpen} onClick={toggleMenu} />
						<LogoLink to={isHideLanding ? "/team" : "/"}>
							<Logo />
						</LogoLink>

						<Exist when={SecretGateController.IS_SECRET_PASSED}>
							<Exist when={!isAuthorized}>
								<SharingWrapper>
									<AccountButton onClick={openAuth} variant="text">
										<span>{i18n.t("landing.copy.login", "Log In")}</span>
										<AccountIcon />
									</AccountButton>
								</SharingWrapper>
							</Exist>
						</Exist>

						<Exist when={isAuthorized}>
							<SharingWrapper>
								<Exist when={location.pathname.includes("team")}>
									<TutorialTooltip>
										<Button
											name="menu-button"
											aria-label="tutorial"
											variant="text"
											onClick={openTutorial}>
											<InfoIcon />
										</Button>
									</TutorialTooltip>
								</Exist>

								<Sharing type={ShareType.General} />
							</SharingWrapper>
						</Exist>
					</MobileContainer>
				</Wrapper>
				<Menu />
				<Notification />
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<Wrapper>
				<Container>
					<Navigation>
						<LogoLink aria-label="Logo" to={isHideLanding ? "/team" : "/"}>
							<Logo />
						</LogoLink>

						<Exist when={!isAuthorized}>
							<NavLink to="/help/prizes">
								{i18n.t("header.navigation.prizes", "Prizes")}
							</NavLink>
							<NavLink to="/help">{i18n.t("header.navigation.help", "Help")}</NavLink>
							<Exist when={SecretGateController.IS_SECRET_PASSED}>
								<SharingWrapper>
									<AccountButton onClick={openAuth} variant="text">
										<span>{i18n.t("landing.copy.login", "Log In")}</span>
										<AccountIcon />
									</AccountButton>
								</SharingWrapper>
							</Exist>
						</Exist>

						<Exist when={isAuthorized}>
							<NavLink to="/team">
								{i18n.t("header.navigation.team", "My Team")}
							</NavLink>
							<NavLink to="/leaderboard">
								{i18n.t("header.navigation.leaderboard", "Leaderboard")}
							</NavLink>
							<NavLink to="/leagues">
								{i18n.t("header.navigation.leagues", "Leagues")}
							</NavLink>
							<NavLink to="/help/prizes">
								{i18n.t("header.navigation.prizes", "Prizes")}
							</NavLink>
							<NavLink to="/help">{i18n.t("header.navigation.help", "Help")}</NavLink>
							<NavLink to="/my-account">
								{i18n.t("header.navigation.account", "My Account")}
							</NavLink>

							<Exist when={isAuthorized}>
								<SharingWrapper>
									<Sharing type={ShareType.General} />
								</SharingWrapper>
							</Exist>
						</Exist>
					</Navigation>
				</Container>
			</Wrapper>
			<Notification />
		</React.Fragment>
	);
});
