import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, reaction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {ITutorialStore} from "data/stores/tutorial/tutorial.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {MetaIntegration} from "data/utils/metaIntegration";

export interface ITutorialTooltipController extends ViewController {
	get isOpen(): boolean;
}

@injectable()
export class TutorialTooltipController implements ITutorialTooltipController {
	@observable _isOpen = false;
	private _disposers: (() => void)[] = [];
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._isOpen;
	}

	init(param: void) {
		this._disposers = [
			reaction(
				() => this.isOpen,
				(isOpen) => {
					if (isOpen) {
						this._isOpen = false;
					}
				},
				{delay: 5000, fireImmediately: true}
			),
			reaction(
				() => this._userStore.user,
				() => this.checkModal(),
				{fireImmediately: true}
			),
		];
	}

	dispose() {
		this._disposers.forEach((dispose) => dispose());
	}

	private checkModal(): void {
		if (this._tutorialStore.isClosed) {
			return;
		}
		if (!this._userStore.user) {
			if (MetaIntegration.isMetaFlow()) {
				return;
			}
			this._isOpen = true;
			return;
		}
		if (!this._tutorialStore.getIsTutorialViewedStorage()) {
			this._isOpen = true;
		}
	}
}
