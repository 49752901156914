import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalTermsController} from "views/components/modals/modal_terms/modal_terms.controller";
import {ModalContent, ModalLogoWrapper} from "views/components/modals/styled";
import {Button, Modal, FormControl, Stack} from "@mui/material";
import {ReactComponent as ModalLogo} from "assets/img/logo/modal_logo.svg";
import {Checkbox, Label, ErrorText} from "views/components/form";
import {FORM_VALIDATION_ELEMENT_CLASSNAME, OPT_IN_TEXT} from "data/constants";
import styled from "@emotion/styled";
import {Exist} from "views/components/exist/exist.component";
import {useLocation} from "react-router";

const Wrapper = styled.div`
	max-width: 320px;
	width: 100%;
`;

const Title = styled.h2`
	color: var(--primaryColor);
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	text-transform: capitalize;
	margin-bottom: 12px;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
`;

const CheckboxLabel = styled(Label)`
	color: var(--primaryColorDark);
	font-size: 14px;
	font-style: normal;
	font-weight: 450;
	line-height: 140%;

	a,
	b {
		font-weight: 700;
	}

	a {
		text-decoration: underline;
	}
`;

const CheckboxStack = styled(Stack)`
	display: flex;
	align-items: center;
`;

const Checkboxes = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	padding-top: 20px;
	padding-bottom: 12px;
`;

// const inputProps = {
// 	className: FORM_VALIDATION_ELEMENT_CLASSNAME,
// 	"data-required": true,
// };

export const ModalTerms: React.FC = observer(() => {
	const location = useLocation();
	const {isOpen, i18n, handleFormSubmit, handleFormChange, isLoading, errorMessage} =
		useViewController<IModalTermsController>(Bindings.ModalTermsController, {
			location,
		});

	return (
		<Modal open={isOpen} disablePortal={true} disableRestoreFocus={true}>
			<ModalContent className="full-screen">
				<ModalLogoWrapper>
					<ModalLogo />
				</ModalLogoWrapper>

				<Wrapper>
					<Title>{i18n.t("modal.auth.register", "Register An account")}</Title>

					<Form onSubmit={handleFormSubmit} onChange={handleFormChange}>
						<Checkboxes>
							<FormControl fullWidth>
								<CheckboxStack direction="row" gap={2}>
									<Checkbox
										name="terms"
										id="terms"
										className={FORM_VALIDATION_ELEMENT_CLASSNAME}
										data-required={true}
										required={true}
									/>
									<CheckboxLabel htmlFor="terms">
										<span
											// Disabled due as Loco - is safe place to render HTML
											/* eslint-disable react/no-danger */
											dangerouslySetInnerHTML={{
												__html: i18n.t(
													"registration.terms.link",
													"I have read and accepted the <a href='/help/terms-&-conditions' target='_blank' rel='noreferrer noopener'>Terms & Conditions</a> and confirm I am over the age of 18."
												),
											}}
										/>
									</CheckboxLabel>
								</CheckboxStack>
							</FormControl>
							<FormControl fullWidth>
								<CheckboxStack direction="row" gap={2}>
									<Checkbox name="federations" id="federations" />
									<CheckboxLabel htmlFor="federations">
										<span>
											{" "}
											{i18n.t("registration.opt_in.label", OPT_IN_TEXT)}
										</span>
									</CheckboxLabel>
								</CheckboxStack>
							</FormControl>
						</Checkboxes>

						<Exist when={!!errorMessage}>
							<ErrorText
								dangerouslySetInnerHTML={{__html: i18n.t(errorMessage || "")}}
							/>
						</Exist>
						<Button disabled={isLoading} type="submit">
							{i18n.t("register.form.action", "Register")}
						</Button>
					</Form>
				</Wrapper>
			</ModalContent>
		</Modal>
	);
});
