import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {DONKEY_INSURANCE_TOTAL, TEAM_HORSE_POSITIONS} from "data/constants";
import {ModalType, RaceStatus} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IHorse} from "data/types/race";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITeam} from "data/types/team";
import type {IRacesStore} from "data/stores/races/races.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {sortBy, last} from "lodash";
import type {IRivalTeamStore} from "data/stores/rival_team/rival_team.store";

export interface IModalDonkeyInsuranceController extends ViewController {
	close: () => void;
	pickDonkey: (horseId: number) => void;
	removeDonkey: () => void;
	getIsButtonDisabled: (horseId: number) => boolean;
	getIsPicked: (horseId: number) => boolean;
	getIsCaptain: (horseId: number) => boolean;

	get i18n(): ILocalizationStore;

	get isOpen(): boolean;

	get isRaceLocked(): boolean;
	get isCorrect(): boolean;
	get isBoosterUsed(): boolean;
	get isInCorrect(): boolean;
	get isComplete(): boolean;

	get horse(): IHorse | undefined;

	get usedCount(): number;

	get totalCount(): number;

	get points(): number | undefined;
}

@injectable()
export class ModalDonkeyInsuranceController implements IModalDonkeyInsuranceController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RivalTeamStore) private _rivalTeamStore: IRivalTeamStore
	) {
		makeAutoObservable(this);
	}

	get totalCount(): number {
		return DONKEY_INSURANCE_TOTAL;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.DONKEY_INSURANCE;
	}

	get isBoosterUsed(): boolean {
		return !this.team.donkeyInsurance && this.team.donkeyInsuranceAllowedCount === 0;
	}

	get isRaceLocked() {
		if (!this.team.race) {
			return false;
		}
		const teamRace = this._racesStore.getRaceById(this.team.race);
		return teamRace?.status !== RaceStatus.Scheduled;
	}

	get finaHorse() {
		if (!this.isComplete) {
			return;
		}
		return last(this.horsesByFinishPosition);
	}

	get horsesByFinishPosition() {
		return sortBy(this._horsesStore.list, (it) => it.finishedPosition || 0);
	}

	get isInCorrect(): boolean {
		if (!this.finaHorse) {
			return false;
		}
		const donkey = this.team.scoreDetails?.donkey;
		const points = donkey?.pts;
		return !points || points < 0;
	}

	get isCorrect(): boolean {
		if (!this.finaHorse) {
			return false;
		}
		const donkey = this.team.scoreDetails?.donkey;
		const points = donkey?.pts;
		return !!points && points > 0;
	}

	get isComplete() {
		if (!this.team.race) {
			return false;
		}
		const teamRace = this._racesStore.getRaceById(this.team.race);
		return teamRace?.status === RaceStatus.Complete;
	}

	get horse(): IHorse | undefined {
		const donkeyInsurance = this.team.donkeyInsurance || 37;
		if (!donkeyInsurance) {
			return;
		}

		if (this._rivalTeamStore.team?.donkeyInsurance) {
			return this._rivalTeamStore.horses.find((it) => it.id === donkeyInsurance);
		}
		return this._horsesStore.getHorseById(donkeyInsurance);
	}

	get usedCount(): number {
		const team = this.team;

		if (team?.donkeyInsurance === undefined) {
			return 0;
		}
		return DONKEY_INSURANCE_TOTAL - team.donkeyInsuranceAllowedCount;
	}

	private get team() {
		return this._rivalTeamStore.team || (this._teamStore.team as ITeam);
	}

	private get horseIds(): number[] {
		return TEAM_HORSE_POSITIONS.map((position) => this.team[position] || -1);
	}

	public getIsButtonDisabled = (horseId: number) => {
		if (horseId === this.team.captain) {
			return true;
		}
		return this.totalCount > 0 && this.usedCount >= this.totalCount;
	};

	public getIsPicked = (horseId: number) => {
		return horseId === this.team.donkeyInsurance;
	};

	public getIsCaptain = (horseId: number) => {
		return horseId === this.team?.captain;
	};

	public close = () => {
		this._modalsStore.hideModal();
	};

	@action
	public pickDonkey = (horseId: number) => {
		if (!horseId || !this._racesStore.selectedRace) {
			return;
		}

		this._teamStore.applyDonkeyInsurance(horseId);
		this.close();
	};

	@action
	public removeDonkey = () => {
		this._teamStore.applyDonkeyInsurance(null);
	};

	get points(): number | undefined {
		const horseId = this.horse?.id;

		if (!horseId) {
			return undefined;
		}
		if (!this.horse?.finishedPosition && this.isComplete) {
			return 0;
		}

		if (!this.horse?.finishedPosition) {
			return;
		}

		return Object.values(this.team.scoreDetails || {}).reduce((acc, value) => {
			if (value?.horse === horseId) {
				return acc + value.pts;
			}
			return acc;
		}, 0);
	}
}
