import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {HorseStatus} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IHorse} from "data/types/race";
import type {IPlayerPoolStore} from "data/stores/player_pool/player_pool.store";
import type {IRacesStore} from "data/stores/races/races.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";

interface IParams {
	horseId: number;
}

export interface IPlayerPoolBoosterActionsController extends ViewController<IParams> {
	addToTeam: () => void;
	removeFromTeam: () => void;

	get i18n(): ILocalizationStore;

	get finishedPosition(): number | undefined | null;

	get isAddEnabled(): boolean;

	get isInjured(): boolean;

	get isLocked(): boolean;

	get isComplete(): boolean;

	get isRemoveEnabled(): boolean;
}

@injectable()
export class PlayerPoolBoosterActionsController implements IPlayerPoolBoosterActionsController {
	@observable private _horseId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.PlayerPoolStore) private _playerPoolStore: IPlayerPoolStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isAddEnabled(): boolean {
		if (this.isLocked) {
			return false;
		}
		return !this.isInTeam && !this.isDonkeyInsurance;
	}

	get isComplete() {
		return this._racesStore.isRaceComplete;
	}

	get isLocked(): boolean {
		return this.isInTeam || this.isLockout;
	}

	get finishedPosition() {
		return this.horse?.finishedPosition;
	}

	get isInjured(): boolean {
		return this.horse?.status === HorseStatus.NonRunner;
	}

	get isRemoveEnabled(): boolean {
		if (this.isLocked) {
			return false;
		}
		return this.isDonkeyInsurance;
	}

	private get isLockout() {
		return this._racesStore.isRaceLocked;
	}

	private get isInTeam(): boolean {
		return this._teamStore.getIsHorseInTeam(this._horseId);
	}

	private get isDonkeyInsurance() {
		return this._teamStore.donkeyInsurance === this._horseId;
	}

	private get isTeamFull(): boolean {
		return this._teamStore.isTeamHorseFull && !!this._teamStore.donkeyInsurance;
	}

	private get horse(): IHorse | undefined {
		return this._horsesStore.getHorseById(this._horseId);
	}

	public removeFromTeam = () => {
		this._teamStore.applyDonkeyInsurance(null);
	};

	init(param: IParams): void {
		this._horseId = param.horseId;
	}

	onChange(param: IParams): void {
		this.init(param);
	}

	public addToTeam = () => {
		if (!this.horse || !this._racesStore.selectedRace) {
			return;
		}
		this._teamStore.applyDonkeyInsurance(this._horseId);
		this._modalsStore.hideModal();
	};
}
