import React, {PropsWithChildren} from "react";
import styled from "@emotion/styled";
import {css} from "@mui/material/styles";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ITutorialTooltipController} from "views/components/tutorial_tooltip/tutorial_tooltip.controller";

const Relative = styled.div`
	position: relative;
`;

const Wrapper = styled.div<{isOpen: boolean}>`
	position: absolute;
	padding: 4px 8px;
	font-size: 12px;
	font-weight: 700;
	white-space: nowrap;
	background: #b99a45;
	top: 100%;
	z-index: 333;
	line-height: 17px;
	border-radius: 4px;
	left: 50%;
	transform: translate(-50%, 0);
	transition: all 0.3s ease;
	&:before {
		content: "";
		position: absolute;
		bottom: 100%;
		left: 50%;
		transform: translate(-50%, 0);
		border-width: 10px;
		border-style: solid;
		border-color: transparent transparent #b99a45 transparent;
	}

	${(props) =>
		!props.isOpen &&
		css`
			opacity: 0;
			pointer-events: none;
		`}
`;

export const TutorialTooltip: React.FC<PropsWithChildren> = observer(({children}) => {
	const {isOpen} = useViewController<ITutorialTooltipController>(
		Bindings.TutorialTooltipController
	);

	return (
		<Relative>
			{children}

			<Wrapper isOpen={isOpen}>View Tutorial</Wrapper>
		</Relative>
	);
});
