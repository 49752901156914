import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ITeam, IUserTeamFetchPayload} from "data/types/team";
import type {ITeamApiProvider} from "data/providers/api/team.api.provider";
import {TeamPosition} from "data/enums";
import {IHorse} from "data/types/race";
import {checkIsTeamPerfect} from "data/utils/team.utils";

export interface IRivalTeamStore {
	get isLoading(): boolean;

	get team(): ITeam | undefined;

	set team(value: ITeam | undefined);

	get raceId(): number;

	set raceId(value: number);

	get isPerfectTeam(): boolean;

	clear(): void;

	fetchTeam(payload: IUserTeamFetchPayload): Promise<void>;

	getHorseByPosition(position: TeamPosition): IHorse | undefined;

	getHorseIdByPosition(position: TeamPosition): number | null | undefined;

	set horses(value: IHorse[]);

	get horses(): IHorse[];
}

@injectable()
export class RivalTeamStore implements IRivalTeamStore {
	set raceId(value: number) {
		this._raceId = value;
	}
	get raceId(): number {
		return this._raceId;
	}
	@observable private _raceId: number = Number.MAX_SAFE_INTEGER;
	@observable private _isLoading: boolean = false;
	@observable private _team: ITeam | undefined;
	@observable private _horses: IHorse[] = [];

	constructor(@inject(Bindings.TeamApiProvider) private _teamApiProvider: ITeamApiProvider) {
		makeAutoObservable(this);
	}

	get horses(): IHorse[] {
		return this._horses;
	}

	set horses(value: IHorse[]) {
		this._horses = value;
	}

	get team() {
		return this._team;
	}

	set team(value: ITeam | undefined) {
		this._team = value;
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	@action
	public async fetchTeam(payload: IUserTeamFetchPayload): Promise<void> {
		try {
			this._isLoading = true;
			const {data} = await this._teamApiProvider.fetchUserTeam(payload);

			runInAction(() => {
				this._team = data.success.team;
			});
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	public getHorseByPosition(position: TeamPosition) {
		const horseId = this._team?.[position];
		return this._horses.find((e) => e.id === horseId);
	}

	public getHorseIdByPosition(position: TeamPosition) {
		return this._team?.[position];
	}

	@action
	public clear() {
		this._isLoading = false;
		this._team = undefined;
		this._horses = [];
	}

	get isPerfectTeam(): boolean {
		if (!this._team) {
			return false;
		}
		return checkIsTeamPerfect(this._team);
	}
}
