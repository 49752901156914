import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Button, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {ModalCloseBlock, ModalContent, ModalLogoWrapper} from "views/components/modals/styled";
import {FormLogin} from "views/components/forms/form_login/form_login.component";
import {FormRegister} from "views/components/forms/form_register/form_register.component";
import {useViewController} from "data/services/locator";
import {IModalAuthController} from "views/components/modals/modal_auth/modal_auth.controller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";
import {ReactComponent as ModalLogo} from "assets/img/logo/modal_logo.svg";
import {useLocation} from "react-router";
import {MetaIntegration} from "data/utils/metaIntegration";

export const ModalAuth: React.FC = observer(() => {
	const location = useLocation();
	const {close, isOpen, isLogin, isRegister} = useViewController<IModalAuthController>(
		Bindings.ModalAuthController
	);

	useEffect(() => {
		if (!location.search.includes("success=true") && !MetaIntegration.isMetaFlow()) {
			close();
		}
	}, [location.search, close]);

	return (
		<Modal open={isOpen}>
			<ModalContent className="full-screen">
				<ModalCloseBlock>
					<Button
						aria-label="close"
						variant="contained"
						color="primary"
						sx={{minWidth: 0}}
						onClick={close}>
						<CloseIcon />
					</Button>
				</ModalCloseBlock>

				<ModalLogoWrapper>
					<ModalLogo />
				</ModalLogoWrapper>

				<Exist when={isLogin}>
					<FormLogin />
				</Exist>
				<Exist when={isRegister}>
					<FormRegister />
				</Exist>
			</ModalContent>
		</Modal>
	);
});
