import {inject, injectable} from "inversify";
import {IUser, IMetaUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";

export interface ITermsPayload {
	terms: boolean;
	federations?: boolean;
}

export interface IRegistrationPayload {
	email: string;
	password: string;
	displayName: string;
	firstName: string;
	surName: string;
	dob: string;
	country: string;
	marketingOptIn: boolean;
	terms: boolean;
	federations: boolean;
	lead_id?: string | null;
}

export interface IUpdateUserPayload {
	email?: string;
	displayName?: string;
	firstName?: string;
	surName?: string;
	isNotificationsEnabled?: boolean;
	password?: string;
}

export interface IUsername {
	username: string;
}

export interface IPreRegisterPayload {
	email: string;
	name: string;
}
export interface IUserPersonalizedModal {
	modal: {
		title: string;
		body: string;
		logo: string;
	};
}
export type TUserResponse = IApiResponse<{user: IUser}>;
export type TMetaUserResponse = IApiResponse<{metaUser: IMetaUser}>;

export interface IUserApiProvider {
	ping: () => Promise<AxiosResponse<unknown>>;
	register: (params: IRegistrationPayload) => Promise<AxiosResponse<TUserResponse>>;
	update: (params: IUpdateUserPayload) => Promise<AxiosResponse<TUserResponse>>;
	user: () => Promise<AxiosResponse<TUserResponse>>;
	meta: (token: string) => Promise<AxiosResponse<TMetaUserResponse>>;
	checkUsername: (params: IUsername) => Promise<AxiosResponse>;
	deactivateAccount: () => Promise<AxiosResponse>;
	modal: () => Promise<AxiosResponse<IApiResponse<IUserPersonalizedModal>>>;
	preRegister: (params: IPreRegisterPayload) => Promise<AxiosResponse<IApiResponse>>;
	terms: (params: ITermsPayload) => Promise<AxiosResponse<TUserResponse>>;
}

@injectable()
export class UserApiProvider implements IUserApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	ping = () => this._http.get<void>("ping");
	checkUsername = (params: IUsername) => this._http.post<void>("user/check_username", params);

	deactivateAccount = () => this._http.post<void>("user/deactivate_account");

	register = (params: IRegistrationPayload) =>
		this._http.post<TUserResponse>("user/register", params);

	update = (params: IUpdateUserPayload) => this._http.post<TUserResponse>("user/update", params);

	user = () => this._http.get<TUserResponse>("user");
	meta = (lead_id: string) =>
		this._http.get<TMetaUserResponse>("user/meta", {
			lead_id,
		});

	modal = () => this._http.get<IApiResponse<IUserPersonalizedModal>>("modal");
	preRegister = (params: IPreRegisterPayload) =>
		this._http.post<IApiResponse>("pre-register", params);

	terms = (params: ITermsPayload) => this._http.post<TUserResponse>("user/terms", params);
}
