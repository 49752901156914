import {ORIGIN_URL} from "data/constants";

export abstract class MetaIntegration {
	public static isMetaFlow(): boolean {
		return !!MetaIntegration.metaToken();
	}

	public static metaToken(): string | null {
		const url = new URL(ORIGIN_URL);
		const search = new URLSearchParams(url.search);
		return search.get("lead_id");
	}
}
