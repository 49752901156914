import {TeamPosition, TeamScoreDetails} from "data/enums";
import {ITeam} from "data/types/team";

export const scoreDetailsPositionMap = {
	[TeamPosition.FirstPlace]: TeamScoreDetails.Correct1st,
	[TeamPosition.SecondPlace]: TeamScoreDetails.Correct2nd,
	[TeamPosition.ThirdPlace]: TeamScoreDetails.Correct3rd,
};

export const getScoreDetailsPosition = (position: TeamPosition) =>
	scoreDetailsPositionMap[position];

export const checkIsTeamPerfect = (
	team: Record<TeamPosition, number | null> & Pick<ITeam, "scoreDetails">
) => {
	const conditions = [
		{
			answer: team[TeamPosition.FirstPlace],
			correctHorse: team.scoreDetails?.[TeamScoreDetails.Correct1st],
		},
		{
			answer: team.secondPlace,
			correctHorse: team.scoreDetails?.[TeamScoreDetails.Correct2nd],
		},
		{
			answer: team.thirdPlace,
			correctHorse: team.scoreDetails?.[TeamScoreDetails.Correct3rd],
		},
	];

	return conditions.every((cond) => {
		const {answer, correctHorse} = cond;
		if (!answer || !correctHorse) {
			return false;
		}
		return answer === correctHorse?.horse;
	});
};
