import "reflect-metadata";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {Suspense, Fragment} from "react";
import reportWebVitals from "./reportWebVitals";
import {RootRoutes, SecretGateRoutes} from "routes";
import {BrowserTracing, init, Integrations, Replay, Event} from "@sentry/react";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {
	controllers,
	DIContainer,
	providers,
	services,
	stores,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import {StyledEngineProvider} from "@mui/material/styles";
import {ThemeProvider} from "@mui/material";
import {
	BASE_URL,
	OTHER_ALLOWED_DOMAINS,
	PREPROD_SITE_DOMAIN,
	PROD_SITE_DOMAIN,
	SENTRY_DSN_URL,
	UAT_SITE_DOMAIN,
} from "data/constants";
import {PagePreloader} from "views/components/preloader";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";
import {Session} from "views/components/session/session.component";
import {Bootstrap} from "views/components/bootstrap/bootstrap.component";
import {ModalError} from "views/components/modals/modal_error/modal_error.component";
import {ModalConfirm} from "views/components/modals/modal_confirm/modal_confirm.component";
import {theme} from "assets/theming/theme";
import {ModalAuth} from "views/components/modals/modal_auth/modal_auth.component";
import {ModalSecondOpt} from "views/components/modals/modal_second_opt/modal_second_opt.component";
import {GeoBlock} from "views/components/geo_block/geo_block.component";
import {ModalTerms} from "views/components/modals/modal_terms/modal_terms.component";
import {ModalPersonalized} from "views/components/modals/modal_personalized/modal_personalized.component";

let errorCount = 0;
const MAX_ERRORS = 100;
if (SENTRY_DSN_URL) {
	init({
		dsn: SENTRY_DSN_URL,
		integrations: [
			new BrowserTracing(),
			new Replay(),
			new Integrations.Breadcrumbs({
				console: false,
			}),
		],
		environment: process.env.REACT_APP_SENTRY_ENV || "development",
		allowUrls: [UAT_SITE_DOMAIN, PREPROD_SITE_DOMAIN, PROD_SITE_DOMAIN, OTHER_ALLOWED_DOMAINS],
		ignoreErrors: [
			"Abort due to cancellation of share",
			"AbortError: Share canceled",
			/Network Error/i,
			/Fetch Error/i,
			/Load failed/i,
		],
		denyUrls: [
			"quantcast",
			"xsca",
			// browser's extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^moz-extension:\/\//i,
		],
		beforeSend: (event: Event) => {
			errorCount++;
			if (errorCount > MAX_ERRORS) {
				return null;
			}
			return event;
		},
		sampleRate: 0.1,
	});
}

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

DIContainer.load(services, providers, stores, controllers);

createRoot(root).render(
	<>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<InjectionProvider container={DIContainer}>
					<Bootstrap>
						<BrowserRouter basename={BASE_URL}>
							<Suspense fallback={<PagePreloader />}>
								{SecretGateController.IS_SECRET_PASSED ? (
									<GeoBlock>
										<Session>
											<RootRoutes />
										</Session>
									</GeoBlock>
								) : (
									<SecretGateRoutes />
								)}
							</Suspense>
							<ModalError />
							<ModalConfirm />
							<ModalAuth />
							<ModalSecondOpt />
							<ModalPersonalized />
							<ModalTerms />
						</BrowserRouter>
					</Bootstrap>
				</InjectionProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
