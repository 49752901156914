import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {IUserStore} from "data/stores/user/user.store";
import {MetaIntegration} from "data/utils/metaIntegration";

export interface IModalAuthController extends ViewController {
	get isOpen(): boolean;
	get isLogin(): boolean;
	get isRegister(): boolean;

	close: () => void;
}

@injectable()
export class ModalAuthController implements IModalAuthController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(): void {
		if (MetaIntegration.isMetaFlow()) {
			this._modalsStore.showModal(ModalType.REGISTER);
		}
	}

	onChange(param: void): void {
		return;
	}

	get isLogin(): boolean {
		return this._modalsStore.modal === ModalType.LOGIN;
	}

	get isOpen(): boolean {
		return this.isLogin || this.isRegister;
	}

	get isRegister(): boolean {
		return this._modalsStore.modal === ModalType.REGISTER;
	}

	public close = () => {
		this._userStore.isPasswordChanged = false;
		this._modalsStore.hideModal();
	};
}
