import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {
	action,
	IReactionDisposer,
	makeAutoObservable,
	observable,
	reaction,
	runInAction,
} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ICountriesStore, ICountry} from "data/stores/countries/countries.store";
import React from "react";
import type {IRegisterFormElement} from "data/types/forms";
import type {IUserStore, IMetaUser} from "data/stores/user/user.store";
import type {IRegistrationPayload} from "data/providers/api/user.api.provider";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IEvent, IEventsStore} from "data/stores/events/events.store";
import type {ISecondOptStore} from "data/stores/second_opt/second_opt.store";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import type {SelectChangeEvent} from "@mui/material";
import type {ITutorialStore} from "data/stores/tutorial/tutorial.store";
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";
import {ModalType, RequestState} from "data/enums";
import type {ISettingsStore} from "data/stores/settings/settings.store";
import {MetaIntegration} from "data/utils/metaIntegration";

interface IParams {
	navigate: ReturnType<typeof useNavigate>;
}
export interface IFormRegisterController extends ViewController<IParams> {
	getErrorMessageByPlace: (place: string) => string | undefined;

	handleFormSubmit: (event: React.SyntheticEvent<IRegisterFormElement, Event>) => void;

	handleFormChange: () => void;

	openLogin: () => void;

	getFormError: (key: string) => string;

	checkResidences: (event: React.SyntheticEvent<HTMLInputElement>) => void;

	handleInputFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

	handleDobChange: () => void;

	disableFinalRegistration: () => void;

	handleSelectFieldChange: (event: SelectChangeEvent<unknown>) => void;

	get i18n(): ILocalizationStore;

	get countries(): ICountry[];

	get metaUser(): IMetaUser | undefined;

	get isLoadingMetaUser(): boolean;

	get isOptChecked(): boolean;

	get hasErrorFromInitialStep(): boolean;

	get events(): IEvent[];

	get errorMsg(): string | null;

	get isLoading(): boolean;

	get formErrors(): Record<string, string>;

	get isFinalRegistration(): boolean;
	onSuccess: () => void;
}

@injectable()
export class FormRegisterController implements IFormRegisterController {
	@observable private subscriptions$: IReactionDisposer[] = [];
	@observable private _isLoading: boolean = false;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _isOptChecked: boolean = false;
	@observable private _isFinalRegistration = false;
	@observable private _navigate?: IParams["navigate"];
	@observable private _requestStateMetaUser: RequestState = RequestState.IDLE;
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.CountriesStore) private readonly _countriesStore: ICountriesStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.EventsStore) private readonly _eventsStore: IEventsStore,
		@inject(Bindings.SecondOptStore) private readonly _secondOptStore: ISecondOptStore,
		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore,
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore
	) {
		makeAutoObservable(this);
	}

	get formErrors() {
		return this._validationHelper.formErrors;
	}

	get isFinalRegistration(): boolean {
		return this._isFinalRegistration;
	}

	get isOptChecked(): boolean {
		return this._isOptChecked;
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get errorMsg(): string | null {
		return this._errorMsg;
	}

	get events(): IEvent[] {
		return this._eventsStore.events;
	}

	public get countries(): ICountry[] {
		return this._countriesStore.list;
	}

	get metaUser(): IMetaUser | undefined {
		return this._userStore.metaUser;
	}

	get isLoadingMetaUser(): boolean {
		return this._requestStateMetaUser === RequestState.PENDING;
	}

	public get hasErrorFromInitialStep(): boolean {
		const fields = ["firstName", "surName", "email", "password", "country", "dob"];

		return Object.keys(this.formErrors).some((key) => {
			const error = this.formErrors[key];
			return error.length > 0 && fields.includes(key);
		});
	}

	public disableFinalRegistration = () => {
		this._isFinalRegistration = false;
	};

	public getErrorMessageByPlace = (name: string) => {
		if (!name || !this._errorMsg || !this._errorPlace) {
			return undefined;
		}

		return this._errorPlace === name ? this._errorMsg : undefined;
	};

	@action
	public handleFormChange = () => {
		if (this._errorMsg) this._errorMsg = null;
		if (this._isLoading) this._isLoading = false;
	};

	public handleFormSubmit = (event: React.SyntheticEvent<IRegisterFormElement, Event>) => {
		event.preventDefault();

		const {firstName, surName, displayName, email, password, country, terms, dob, federations} =
			event.currentTarget;

		const payload: IRegistrationPayload = {
			firstName: firstName.value,
			surName: surName.value,
			displayName: displayName.value,
			email: email.value,
			password: password.value,
			dob: dob.value,
			country: country.value,
			marketingOptIn: federations.checked,
			terms: terms.checked,
			federations: federations.checked,
			lead_id: MetaIntegration.metaToken(),
		};

		if (!this.checkValidity(event.currentTarget)) {
			return;
		}

		if (!this._isFinalRegistration) {
			this._isFinalRegistration = true;
			return;
		}

		this._isLoading = true;
		this._userStore
			.register(payload)
			.then(this.onSuccess)
			.catch(this.onError)
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	};

	public openLogin = () => {
		this._modalsStore.showModal(ModalType.LOGIN);
	};

	public getFormError = (key: string) => {
		return this.formErrors[key] || "";
	};

	dispose(): void {
		this.subscriptions$.forEach((dispose) => dispose());
	}

	init(param: IParams): void {
		this._navigate = param.navigate;
		void this._countriesStore.fetchCountries();
		void this._eventsStore.fetchEvents();

		this.checkFederations();
		this.fetchFederations();

		this.subscriptions$ = [
			reaction(
				() => this._secondOptStore.isModalOpen,
				() => this.checkFederations()
			),
			reaction(
				() => this.hasErrorFromInitialStep,
				() => {
					this._isFinalRegistration = false;
				}
			),
			reaction(
				() => this.metaToken,
				(token) => {
					if (token) {
						this.fetchMetaData(token);
					}
				},
				{fireImmediately: true}
			),
		];
	}

	onChange(param: IParams): void {
		this._navigate = param.navigate;
	}

	@action
	public handleInputFieldChange = ({target: {name}}: React.ChangeEvent<HTMLInputElement>) => {
		if (["password", "confirmPassword"].includes(name)) {
			this._validationHelper.clearFormFieldError("password");
			this._validationHelper.clearFormFieldError("confirmPassword");
		} else {
			this._validationHelper.clearFormFieldError(name);
		}
	};

	@action
	public handleSelectFieldChange = (event: SelectChangeEvent<unknown>) => {
		const name = event.target.name;

		if (!name) {
			return;
		}
		this._validationHelper.clearFormFieldError(name);
	};

	@action
	public handleDobChange = () => {
		this._validationHelper.clearFormFieldError("dob");
	};

	public checkResidences = (event: React.SyntheticEvent<HTMLInputElement>) => {
		this._isOptChecked = event.currentTarget.checked;
		if (event.currentTarget.checked) {
			this._secondOptStore.openModal();
		} else {
			this._secondOptStore.selectedFederations = [];
		}
	};

	private fetchFederations() {
		void this._secondOptStore.fetchFederations();
	}

	@action
	onSuccess = () => {
		this._userStore.isSuccessRegister = true;
		this._navigate?.("/success-registration");
		this._tutorialStore.clearTutorialStorage();
		this._modalsStore.hideModal();
	};

	@action
	private onError = (error: AxiosError<IApiResponse>) => {
		const errors = error.response?.data?.errors;
		this._errorMsg =
			errors?.map(({message}) => this.i18n.t(message, message)).join("<br/>") || "";
	};

	private checkFederations() {
		const length = this._secondOptStore.selectedFederations.length;
		const isOpen = this._secondOptStore.isModalOpen;

		if (length < 1 && !isOpen) {
			this._isOptChecked = false;
		}
	}

	private checkValidity(form: IRegisterFormElement) {
		const {password, confirmPassword} = form;
		const {checkValidity, setFormFieldError, errors} = this._validationHelper;

		checkValidity(form);

		if (confirmPassword.value !== password.value) {
			setFormFieldError(confirmPassword.name, errors.password_mismatch);
		}

		if (!this._validationHelper.isValid) {
			return false;
		}

		return this._validationHelper.isValid;
	}

	private get metaToken() {
		return MetaIntegration.metaToken();
	}

	private fetchMetaData(token: string) {
		this._requestStateMetaUser = RequestState.PENDING;
		this._userStore
			.requestMetaUserData(token)
			.then(() => {
				runInAction(() => {
					this._requestStateMetaUser = RequestState.SUCCESS;
				});
			})
			.catch(() => {
				this._requestStateMetaUser = RequestState.ERROR;
			});
	}
}
